


















export default {
  name: "HomeSliderCard",
  props: {
    item: {
      type: Object,
      required: false,
      default: {},
    },
  },
};
