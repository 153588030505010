//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref } from "@nuxtjs/composition-api";
import VueSlickCarousel from "vue-slick-carousel";
import HomeSLiderCard from "../HomeSLiderCard.vue";
import { Icon } from "@iconify/vue2";

export default defineComponent({
  name: "TheHomeSlider",
  props: ["blok"],
  components: {
    VueSlickCarousel,
    HomeSLiderCard,
    Icon,
  },
  setup() {
    const settings = ref({
      infinite: true,
      arrows: true,
      dots: false,
      slidesToShow: 3,
      speed: 500,
      rows: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            arrows: true,
            dots: false,
          },
        },
      ],
    });

    return {
      settings,
    };
  },
});
